export default [
  {
    key: 'gameId',
    label: 'field.game_id',
    type: 'text',
    cols: 12,
    rules: 'required',
  },
  {
    key: 'zoneId',
    label: 'field.serverId',
    type: 'text',
    cols: 12,
    rules: 'required',
  },
  {
      key: 'packageId',
      label: 'field.package',
      type: 'nAsynSingleSelection',
      repository: 'package',
      selectionKey: 'id',
      selectionLabel: 'displayText',
      rules: 'required',
      cols: 12,
  },
]
